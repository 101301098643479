<template>
	<div class="authorityDialog">
		<el-dialog
			v-model="visible"
			width="546px"
			title="功能权限"
			:before-close="handleClose"
		>
			<el-form
				ref="formRef"
				:model="formData"
				label-width="110px"
				:rules="rules"
			>
				<el-row>
					<el-col :span="24">
						<el-form-item label="角色名称：" prop="roleName">
							<el-input
								placeholder="请输入"
								disabled
								v-model="formData.roleName"
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<div class="box">
							<div class="title">权限</div>
							<!-- <div class="title">
								<el-checkbox
									v-model="allChecked"
									label="全部选择"
									size="large"
								/>
							</div> -->
							<div>
								<el-tree
									ref="treeRef"
									:data="treeData"
									show-checkbox
									:node-key="'menuId'"
									highlight-current
									draggable
									:props="defaultProps"
									:default-checked-keys="checkedIds"
									@check-change="checkChange"
									@check="check"
								/>
							</div>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="handleClose">取 消</el-button>
					<el-button type="primary" @click="saveItem(formRef)">提 交</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script setup>
import {
	ref,
	reactive,
	toRefs,
	computed,
	watch,
	onMounted,
	onUnmounted,
	getCurrentInstance,
} from "vue";
import axios from "axios";
import $store from "@/store";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();
const emit = defineEmits(["close"]);
const props = defineProps(["propsData", "visible"]);
const { propsData } = props;

let formData = ref({});
let rules = ref({
	roleName: [{ trigger: "blur", required: true, message: "角色名不能为空" }],
});
const treeRef = ref(null);
const defaultProps = {
	children: "children",
	label: "menuName",
};
let allChecked = ref(false); //是否全选

const treeData = reactive([]);
const listData = reactive([]);
const checkedIds = reactive([]);
function getMenuList() {
	axios({
		url: "/system/menu/list",
		method: "get",
	}).then((res) => {
		setTreeData(res, treeData);
	});
}

function setTreeData(list, tree) {
	let map = {
		1: {
			children: [],
		},
	};

	list.forEach((e) => {
		map[e.menuId] = e;
	});
	list.forEach((e) => {
		map[e.parentId] && map[e.parentId].children.push(e);
	});
	tree.push(...map[1].children);
}
function checkChange(data, node) {
	// console.log("checkChange", data, node);
}
function check(node, checks) {
	console.log("check", node, checks);
	checkedIds.length = 0; //清空
	checkedIds.push(...checks.checkedKeys);
}

function handleClose() {
	emit("update:visible", false);
}
function saveItem() {
	let data = {
		...propsData,
		menuIds: checkedIds,
	};
	console.log("saveItem", propsData, checkedIds, data);
	proxy.$api.role.update(data).then((res) => {
		console.log(res);
		proxy.$resMsg(res);
		handleClose();
	});
}

// 监听全选
watch(allChecked, (nv, ov) => {
	console.log(nv);
	if (nv) {
		console.log("全选");
		let arr = [];
		listData.forEach((i) => {
			arr.push(i.menuId);
		});
		console.log(arr);
		treeRef.value.setChecked(arr);
	}
});
function getRoleData() {
	proxy.$api.role.getById(propsData.roleId).then((res) => {
		console.log("getRoleData", res.data, checkedIds);
		treeRef.value.setCheckedKeys(res.data.menuIds); //设置默认拥有的角色
	});
}

onMounted(() => {
	getRoleData();
	getMenuList();
	if (propsData.roleId) {
		formData.value = propsData;
	}
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.authorityDialog {
	.title {
		display: flex;
		align-items: center;
	}
	.line {
		margin-right: 8px;
		display: inline-block;
		width: 3px;
		height: 16px;
		line-height: 16px;
		background: #1492ff;
	}
	.row-title {
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		margin-bottom: 16px;
	}
	.upload-demo {
		/deep/ .el-input__inner {
			width: 400px;
		}
	}
	.box {
		width: 100%;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		border-radius: 2px 0 0 2px;
		border-radius: 2px 0 0 2px;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		.title {
			height: 40px;
			line-height: 40px;
			padding-left: 16px;
			background: #f6f6f6;
			border-bottom: 1px solid #e5e5e5;
			border-radius: 2px 2px 0 0;
			border-radius: 2px 2px 0 0;
		}
	}
}
</style>
