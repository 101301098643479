<template>
	<div class="editUser">
		<el-dialog
			v-model="visible"
			width="546px"
			:title="title"
			:before-close="handleClose"
		>
			<el-form
				ref="formRef"
				:model="formData"
				label-width="120px"
				:rules="rules"
			>
				<el-row>
					<el-col :span="24">
						<el-form-item label="角色名称：" prop="roleName">
							<el-input
								placeholder="请输入"
								v-model="formData.roleName"
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="角色英文名称：" prop="roleKey">
							<el-input
								placeholder="请输入"
								v-model="formData.roleKey"
								disabled
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="角色简介：" prop="remark">
							<el-input
								type="textarea"
								rows="4"
								placeholder="请输入"
								v-model="formData.remark"
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="角色状态：" prop="status">
							<el-radio v-model="formData.status" label="0" size="large"
								>启用</el-radio
							>
							<el-radio v-model="formData.status" label="1" size="large"
								>禁用</el-radio
							>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="handleClose">取 消</el-button>
					<el-button type="primary" @click="saveItem(formRef)">提 交</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
const router = useRouter();
const emit = defineEmits(["close"]);
const props = defineProps(["propsData", "title", "visible"]);
const { title, propsData } = props;
let dialogVisible = true;
let formData = ref({});
let rules = ref({
	roleName: [{ trigger: "blur", required: true, message: "请输入角色名称" }],
	roleKey: [{ trigger: "blur", required: true, message: "请输入角色英文名称" }],
	status: [{ trigger: "blur", required: true, message: "请输入角色状态" }],
});
function handleClose() {
	emit("update:visible", false);
}
let formRef = ref({});
const saveItem = async (formRef) => {
	if (!formRef) return;
	await formRef.validate((valid, fields) => {
		if (valid) {
			if (propsData.roleId) {
				axios.put(`/system/role`, formData.value).then((res) => {
					console.log(res);
					ElMessage({
						message: "编辑成功",
						type: "success",
					});

					handleClose();
				});
			} else {
				axios.post(`/system/role/`, formData.value).then((res) => {
					console.log(res);
					ElMessage({
						message: "新增成功",
						type: "success",
					});
					handleClose();
				});
			}
		} else {
			console.log("error submit!", fields);
		}
	});
};

watch(() => {});
onMounted(() => {
	console.log(propsData);
	if (propsData.roleId) {
		formData.value = propsData;
	}
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.addOrEdit {
	.title {
		display: flex;
		align-items: center;
	}
	.line {
		margin-right: 8px;
		display: inline-block;
		width: 3px;
		height: 16px;
		line-height: 16px;
		background: #1492ff;
	}
	.row-title {
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		margin-bottom: 16px;
	}
	.upload-demo {
		/deep/ .el-input__inner {
			width: 400px;
		}
	}
}
</style>
