<template>
	<div class="addUser">
		<el-dialog
			v-model="visible"
			width="65%"
			:title="propsData.roleName+' - 添加人员'"
			:before-close="handleClose"
		>
			<el-form
				ref="formRef"
				:model="formData"
				label-width="110px"
				:rules="rules"
			>
				<el-row>
					<el-col :span="16">
						<el-row>
							<el-col :span="10">
								<el-form-item label="部门：" prop="deptName ">
									<el-input
										placeholder="请输入"
										v-model="form.deptName"
									></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<el-form-item label="人员：" prop="userName ">
									<el-input
										placeholder="请输入"
										v-model="form.userName"
									></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="4">
								<el-button
									class="serchBtn"
									type="primary"
									@click="getTreeData('search')"
									>搜索</el-button
								>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="11">
								<div class="box">
									<div class="title">部门</div>
									<div class="tree">
										<el-tree
											ref="treeRef"
											:data="allDepartment"
											node-key="id"
											@node-click="handleNodeClick"
											@check-change="handleChange"
											@node-expand="handleNodeClick"
											:filter-node-method="filterNodeMethod"
											:load="loadNode"
											lazy
											highlight-current
											:props="defaultProps"
										/>
									</div>
								</div>
							</el-col>
							<el-col :span="2" class="img">
								<img src="../../../assets/img/down.png" alt="" />
							</el-col>
							<el-col :span="11">
								<div class="box">
									<div class="title">
										<span>{{ currentDepName }}</span>
										<span class="people">{{ tableData.length }}人</span>
									</div>
									<div class="table">
										<el-table
											:data="tableData"
											size="medium"
											style="width: 100%; height: 100%"
											height="calc(100% - 40px)"
											:header-cell-style="{
												background: '#F7F8FA',
												color: '#333333',
												height: '40px',
												'font-size': '14px',
											}"
											@selection-change="handleSelectChange"
										>
											<el-table-column label="序号" type="index">
											</el-table-column>
											<el-table-column prop="nickName" label="账户名">
											</el-table-column>
											<el-table-column
												label="操作"
												type="selection"
												:selectable="selectable"
											>
											</el-table-column>
										</el-table>
									</div>
								</div>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="1"> </el-col>
					<el-col :span="7">
						<div class="box box2">
							<div class="title">
								<span>已添加人员</span>
								<span>{{ addedData.length }}人</span>
							</div>
							<div class="addedPer">
								<el-table
									:data="addedData"
									size="medium"
									style="width: 100%; height: 100%"
									height="calc(100% - 40px)"
									:header-cell-style="{
										background: '#F7F8FA',
										color: '#333333',
										height: '40px',
										'font-size': '14px',
									}"
								>
									<el-table-column label="序号" type="index"> </el-table-column>
									<el-table-column prop="nickName" label="账户名">
									</el-table-column>
									<el-table-column label="操作" width="">
										<template #default="scope">
											<el-button type="text" size="small">
												<el-popconfirm
													title="确定删除吗？"
													@confirm="handleDel(scope.$index, scope.row)"
												>
													<template #reference>
														<span style="color: red; fontsize: 16px"
															><el-icon><delete /></el-icon
														></span>
													</template>
												</el-popconfirm>
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="handleClose">取 消</el-button>
					<el-button type="primary" @click="saveItem(formRef)">提 交</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script setup>
import {
	ref,
	reactive,
	computed,
	watch,
	onMounted,
	onUnmounted,
	getCurrentInstance,
} from "vue";
import { toRaw } from "@vue/reactivity";
import { useRouter } from "vue-router";
import user from "../../../store/modules/user";
const router = useRouter();
const emit = defineEmits(["close"]);
const props = defineProps(["propsData", "visible"]);
const { proxy } = getCurrentInstance();
const { propsData } = props;
const { roleId } = propsData; //角色ID
let formData = ref({});
const tableData = ref([]);
const addedData = ref([]); //已经添加的人选
const allDepartment = ref([]);
let checkData = ref([]);//新添加的人
let currentDepName = ref("");
let form = ref({
	uacDeptId: "top",
	deptName: "",
	userName: "",
});
let defaultProps = {
	isLeaf: "leaf",
	label: "deptName",
	value: "deptId",
	children: "children",
};

// 查询已添加人员
function getAddedPeo(params) {
	axios({
		url: "/system/role/authUser/allocatedList",
		method: "get",
		params: params ? params : {},
	}).then((res) => {
		addedData.value = res.records;
	});
}
function selectable(row, index) {
	let result = true;
	let { userId, nickName } = toRaw(row);
	console.log(userId, nickName, addedData.value.includes(userId));
	addedData.value.forEach(i=>{
		// console.log(i)
		if(i.userId === userId){
			result = false
		}
	})
	return result;
}

// tree结构所用接口方法
function getTreeData(id) {
	id == "search" ? (form.value.uacDeptId = "") : (form.value.uacDeptId = "top");
	axios({
		url: "/system/dept/uacList",
		method: "get",
		params: form.value,
	}).then((res) => {
		allDepartment.value = res;
		id == "search" ? handleNodeClick(allDepartment.value[0]) : "";
	});
}
function handleNodeClick(data) {
	console.log(data);
	axios({
		url: "/system/dept/uacList",
		method: "get",
		params: {
			uacDeptId: data.uacDeptId,
		},
	}).then((res) => {
		// length为0代表到了最后的节点需要请求人员
		if (res.length !== 0) {
			console.log("handleNodeClick", data);
			data.children = res;
		} else {
			currentDepName.value = data.deptName;
			console.log(data, currentDepName.value);
			axios({
				url: "/system/dept/getUserByDeptId",
				method: "get",
				params: {
					deptId: data.deptId,
				},
			}).then((peo) => {
				tableData.value = peo;
			});
		}
	});
}
function filterNodeMethod(value, data) {
	return data.label !== value;
}
function loadNode(node, resolve) {
	if (node.level === 0) {
		return resolve(node.data);
	}
	getOftenTreeData(node.data.uacDeptId || node.data.deptId).then((res) => {
		// length为0代表到了最后的节点需要请求人员
		if (res.length !== 0) {
			node.data.children = res;
			resolve(res);
		} else {
			currentDepName.value = node.data.deptName;
			resolve([]);
		}
	});
}
function getOftenTreeData(path = "top") {
	return axios({
		url: "/system/dept/uacList",
		method: "get",
		params: {
			uacDeptId: path,
		},
	}).then((res) => {
		path == "top" ? (allDepartment.value = res) : "";
		return res;
	});
}
function handleChange(data, checked) {
	console.log(data, checked);
}

// 部门所选人员
function handleSelectChange(data) {
	console.log(data);
	checkData.value = data;
}
// 已添加人员
function handleConfirmChange(data) {
	console.log(data);
}
// 删除已添加人员
function handleDel(index, row) {
	let { userId } = toRaw(row);

	let data = {
		roleId: roleId,
		userIds: userId,
	};

	proxy.$api.role.unAuthUser(data).then((res) => {
		console.log("取消授权", res);
		if (res.code === 200) {
			addedData.value.splice(index, 1);
		}
	});
}

function handleClose() {
	emit("update:visible", false);
}
function saveItem() {
	let users = checkData.value;
	let userIds = [];
	let userIdsStr = "";
	users.forEach((i) => {
		let { userId } = toRaw(i);
		userIds.push(userId);
	});
	console.log(userIds, userIdsStr);
	let data = {
		roleId: roleId,
		userIds: userIds,
	};
	proxy.$api.role.authUser(data).then((res) => {
		proxy.$resMsg(res);
		if(res.code === 200){
			addedData.value.push(...checkData.value)
			checkData.value = []
		}
	});
}
watch(() => {});
onMounted(() => {
	getTreeData("top");
	getAddedPeo({
		roleId: roleId,
        page:-1,
        size:-1,
	});
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.addUser {
	.title {
		display: flex;
		align-items: center;
	}
	.line {
		margin-right: 8px;
		display: inline-block;
		width: 3px;
		height: 16px;
		line-height: 16px;
		background: #1492ff;
	}
	.row-title {
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		margin-bottom: 16px;
	}
	.upload-demo {
		/deep/ .el-input__inner {
			width: 400px;
		}
	}
	.serchBtn {
		margin-left: 8px;
	}
	.box {
		width: 100%;
		background: #ffffff;
		border: 1px solid #e5e5e5;
		border-radius: 2px 0 0 2px;
		border-radius: 2px 0 0 2px;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		height: 400px;
		box-sizing: border-box;
		.title {
			min-height: 40px;
			padding: 0 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #f6f6f6;
			border-bottom: 1px solid #e5e5e5;
			border-radius: 2px 2px 0 0;
			border-radius: 2px 2px 0 0;
            .people{
                white-space: nowrap;
                width: 20px;
                margin-left: 15px;
            }
		}
		.tree {
			overflow-y: auto;
			height: 348px;
			padding: 12px 16px;
			box-sizing: border-box;
		}
		.table {
			height: 338px;
			overflow-y: auto;
			padding: 12px;
		}
	}
	.box2 {
		height: 448px;
		.addedPer {
			height: 400px;
		}
	}
	.img {
		text-align: center;
		line-height: 30;
		img {
			width: 16px;
			height: 16px;
		}
	}

	/deep/ .el-form-item__label {
		width: 80px !important;
	}
    /deep/ .el-tree-node{
        white-space: normal;
        .el-tree-node__content{
            height: auto;
        }
    }
}
</style>
