<template>
    <div class="userManagement">
        <header>
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="角色名称：">
                    <el-input class="search-input" clearable v-model="query.roleName" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="创建人：">
                    <el-input class="search-input" clearable v-model="query.createBy" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="角色状态：">
                    <el-select class="search-input" v-model="query.status" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="btns">
                <el-button type="primary" @click="getTableData">查 询</el-button>
                <el-button plain @click="resetForm">重 置</el-button>
            </div>
        </header>
        <main>
            <div class="btns">
                <el-button plain @click="addRole">新增角色</el-button>
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    height="70vh"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column
                        v-for="(item, index) in columns"
                        :key="index"
                        :prop="item.prop"
                        :label="item.label"
                        :width="item.width"
                    ></el-table-column>
                    <el-table-column label="状态" width="">
                        <template #default="{ row }">
                            <span style="color: #009949" v-if="row.status == '0'">启用</span>
                            <span style="color: #fd3939" v-else>禁用</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" sortable label="创建时间"></el-table-column>
                    <el-table-column label="操作" width="220">
                        <template #default="{ row }">
                            <el-button type="text" size="small" @click="openauthority(row)">功能权限</el-button>
                            <el-button type="text" size="small" @click="addUser(row)">添加人员</el-button>
                            <el-button v-auth="['admin', 'administrator']" type="text" size="small" @click="editRole(row)">修改</el-button>
                            <el-button type="text" size="small" v-auth="['administrator']">
                                <el-popconfirm title="确定删除吗？" @confirm="handleDel(row)">
                                    <template #reference>
                                        <span style="color: red">删除</span>
                                    </template>
                                </el-popconfirm>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        v-model:currentPage="query.page"
                        v-model:page-size="query.size"
                        :page-sizes="[15, 30, 50, 100]"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="getTableData"
                        @current-change="getTableData"
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
        <EditUser v-if="showEditUser" v-model:visible="showEditUser" :title="titleName" :propsData="formData" @close="addOrEditClose" />
        <AuthorityDialog v-if="authorityDialog" v-model:visible="authorityDialog" :propsData="formData" @close="authorityDialog = false" />
        <AddUserDialog v-if="addUserDialog" v-model:visible="addUserDialog" @close="addUserDialog = false" :propsData="addData" />
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

import EditUser from '@/views/userManagement/dialog/editUser';
import AuthorityDialog from '@/views/userManagement/dialog/authorityDialog';
import AddUserDialog from '@/views/userManagement/dialog/addUserDialog';
const router = useRouter();
const emit = defineEmits(['close']);
const props = defineProps({});
const { proxy } = getCurrentInstance();
let query = ref({
    page: 1,
    size: 15,
    roleName: '',
    createBy: '',
    status: '',
});
let total = ref(0);
let tableData = ref([]);
let addData = ref({});
let showEditUser = ref(false);
let addOrEditDialog = ref(false);
let addUserDialog = ref(false);
let authorityDialog = ref(false);
let titleName = ref('');
let formData = ref({});
const options = [
    {
        value: '0',
        label: '启用',
    },
    {
        value: '1',
        label: '禁用',
    },
];
let columns = ref([
    {
        prop: 'roleId',
        label: '角色id',
        width: '100',
    },
    {
        prop: 'roleName',
        label: '角色名称',
        width: '160',
    },
    {
        prop: 'remark',
        label: '角色简介',
        width: '500',
    },
    {
        prop: 'createBy',
        label: '创建人',
        width: '100',
    },
]);
function getTableData() {
    axios({
        url: '/system/role/list',
        method: 'get',
        params: query.value,
    }).then(res => {
        console.log('角色列表', res);
        tableData.value = res.records;
        total.value = res.total;
    });
}
function editRole(row) {
    titleName.value = '角色修改';
    showEditUser.value = true;
    formData.value = row;
}
function handleDel(row) {
    console.log(row);
    axios.delete(`/system/role/${row.roleId}`).then(res => {
        ElMessage({
            message: '删除成功',
            type: 'success',
        });
        getTableData();
    });
}
function addRole(row) {
    titleName.value = '新增角色';
    showEditUser.value = true;
    formData.value = row;
}
function openauthority(row) {
    let { roleId } = row;
    console.log(row, roleId);
    roleId &&
        getRoleResource(roleId).then(res => {
            console.log('角色资源', res);
        });
    authorityDialog.value = true;
    formData.value = row;
}
let addUser = row => {
    addUserDialog.value = true;
    addData.value = row;
};
function handleSelectionChange() {}
function addOrEditClose() {
    showEditUser.value = false;
    getTableData();
}
function getRoleResource(roleId) {
    return proxy.$api.role.getRoleResource(roleId);
}
const resetForm = () => {
    query.value = {
        page: 1,
        size: 15,
        roleName: '',
        createBy: '',
        status: '',
    };
    getTableData();
};
function getRoleDataById(roleId) {
    return proxy.$api.role.getById(roleId);
}
watch(() => {});
onMounted(() => {
    getTableData();
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.userManagement {
    header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
    }
    header {
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100vh - 164px);
        padding: 16px 24px 58px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .btns {
            text-align: right;
        }
        .table {
            margin-top: 16px;
            .pagination {
                text-align: right;
                margin-top: 10px;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
}
</style>
